<template>
  <v-dialog
    v-model="dialog"
    width="1200"
    :scrollable="true"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{mdiCreation}}
        </v-icon>
      </v-btn>
    </template>
    <v-card class="ma-3">
      <v-row>
        <v-col cols="8" >
          <v-card class="ma-3">
            <v-card-text>
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    v-model="partner_id"
                    :items="partners"
                    item-text="title"
                    item-value="id"
                    placeholder="Партнёрка"
                    label="Партнёрка"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="shop_id"
                    outlined
                    :items="shops"
                    item-value="id"
                    item-text="name"
                    label="Магазин"
                    placeholder="Магазин"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    ref="raw_link"
                    v-model="raw_link"
                    :success-messages="successMessages"
                    :error-messages="errorMessages"
                    :prepend-icon="linkIcon"
                    :loading="linkChecking"
                    label="Ссылка"
                    placeholder="Ссылка"
                    outlined
                    autofocus
                  >
                  </v-text-field>
                </v-col>
                <v-col col="4">
                  <v-combobox
                    v-model="sub"
                    label="SUB"
                    placeholder="SUB"
                    :items="userSubs"
                    item-text="sub"
                    item-value="sub"
                    :return-object="false"
                    outlined
                  >
                  </v-combobox>
                </v-col>
                <v-col col="4">
                  <v-combobox
                    v-model="sub1"
                    label="SUB 1"
                    placeholder="SUB 1"
                    :items="userSubs"
                    item-text="sub"
                    item-value="sub"
                    :return-object="false"
                    outlined
                  >
                  </v-combobox>
                </v-col>
                <v-col col="4">
                  <v-combobox
                    v-model="sub2"
                    label="SUB 2"
                    placeholder="SUB 2"
                    :items="userSubs"
                    item-text="sub"
                    item-value="sub"
                    :return-object="false"
                    outlined
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row v-if="finalLink">
                <v-col col="8">
                  <v-alert
                    type="success"
                    prominent
                    :icon="mdiCreation"
                  >
                    <span class="text-yes-wrap" v-html="finalLink"></span>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">
                  <v-btn color="info"
                         :loading="isCreating"
                         :disabled="isCreating"
                         @click="createLink()"
                  >
                    <v-icon
                      left
                      dark
                    >
                      {{mdiCreation}}
                    </v-icon>
                    Создать ссылку
                  </v-btn>
                </v-col>
                <v-col v-if="finalLink" cols="4">
                  <v-btn color="success" @click="copy()">
                    <v-icon
                      left
                      dark
                    >
                      {{mdiContentCopy}}
                    </v-icon>
                    Скопировать ссылку
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="ma-3">
            <v-virtual-scroll
              :bench="5"
              :items="links"
              height="350"
              item-height="80"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.id" @click="copy(item.final_link)">
                  <v-list-item-content>
                    <v-list-item-title v-text="'SUB: ' + item.sub + ' ' + item.sub1 + ' ' + item.sub2"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.raw_link"></v-list-item-subtitle>
                    <v-list-item-subtitle v-text="timeLeft(item.created_at)"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </template>
              <!--              <v-list three-line>-->
              <!--                <template v-for="(link, i) in links">-->
              <!--                  <v-divider :key="i"></v-divider>-->
              <!--                  <v-list-item-->
              <!--                    :key="link.id"-->
              <!--                    @click="copy(link.final_link)"-->
              <!--                  >-->
              <!--                    -->
              <!--                  </v-list-item>-->
              <!--                </template>-->
              <!--              </v-list>-->
            </v-virtual-scroll>

          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar
      v-model="snackbarLinkCopied"
      timeout="1000"
    >
      Ссылка скопирована
    </v-snackbar>
  </v-dialog>
</template>

<script>
import {mdiThumbUp, mdiThumbDown, mdiThumbUpOutline, mdiCreation, mdiContentCopy, mdiInformationOutline} from '@mdi/js'
import _ from 'lodash'
import QueryString from 'qs'
import moment from 'moment'
import httpClient from '@/services/http.service'

require('moment/locale/ru')

export default {
  name: 'Linker',
  data(){
    return{
      sub: '',
      sub1: '',
      sub2: '',
      partner_id: 0,
      shop_id: 0,
      offer_id : 0,
      raw_link: '',
      mdiThumbUp, mdiCreation, mdiContentCopy, mdiThumbDown, mdiThumbUpOutline, mdiInformationOutline,
      dialog: false,
      shops: this.$store.state.shops,
      partners: JSON.parse(JSON.stringify(this.$store.state.partners)),
      isCreating: false,
      finalLink: false,
      snackbarLinkCopied: false,
      successMessages: '',
      errorMessages: '',
      linkIcon: mdiInformationOutline,
      linkChecking: false
    }
  },
  computed:{
    link(){
      return {shop_id: this.shop_id, partner_id: this.partner_id, raw_link: this.raw_link, sub: this.sub, sub1: this.sub1, sub2: this.sub2, offer_id: this.offer_id}
    },
    links(){
      return this.$store.state.magicLinks
    },
    userSubs(){
      return this.$store.state.userSubs
    }
  },
  watch:{
    dialog(v){
      if (!v){
        this.clean()
      }
    },
    raw_link(v){
      try {
        const url = new URL(v)
        if (url.host === 'aliexpress.ru' || url.host === 'aliexpress.com' || url.host === 'www.aliexpress.ru' || url.host === 'www.aliexpress.com'){
          this.checkAepLink(v)
          this.shop_id = 1
        } else if (url.host === 'www.citilink.ru'){
          this.shop_id = 3
        } else if (url.host === 'www.eldorado.ru'){
          this.shop_id = 4
        } else if (url.host === 'market.yandex.ru'){
          this.shop_id= 5
        } else if (url.host === 'www.mvideo.ru'){
          this.shop_id = 6
        } else if (url.host === 'shop.mts.ru'){
          this.shop_id = 7
        } else if (url.host === 'www.ozon.ru'){
          this.shop_id = 8
        }

        this.disablePartners() //выключит недоступных партнёров
        this.checkOfferId()
      } catch (error) {
        console.log('not URL: ', v, error)
      }
    },
    partner_id(){ //для EPN и AdmitAD нужно выбирать ID оффера (магазина)
      this.checkOfferId()
    },
    shop_id(){
      this.checkOfferId()
    }
  },
  created() {
    this.$store.dispatch('getShops').then(shops => { this.shops = shops })
    this.$store.dispatch('getPartners').then(partners => { this.partners = partners })
    this.$store.dispatch('getUserSubs')
    this.$store.dispatch('getMagicLinks')
  },
  methods:{
    disablePartners(){
      const id = this.shop_id
      _.each(this.partners, x => {
        x.disabled = false
      }) //сначала всех включим, и отключим всё что неьзя
      if (id === 1 || id === 2) { //ali
        this.partners.find(x => x.id === 3).disabled = true
        this.partner_id = 1
      } else if (id === 3 || id === 4 || id === 5) { //ситилинк эльдорадо яндекс маркет
        this.partners.find(x => x.id === 1).disabled = true
        // this.partners.find(x => x.id === 3).disabled = true
        this.partner_id = 3
      } else if (id === 6) { //мвидео
        this.partners.find(x => x.id === 1).disabled = true
        this.partners.find(x => x.id === 2).disabled = true
        this.partner_id = 3
      } else if (id === 7) { //мтс
        this.partners.find(x => x.id === 1).disabled = true
        this.partner_id = 2
      } else if (id===8) { //8 - озон
        this.partners.find(x => x.id === 1).disabled = true
        this.partners.find(x => x.id === 2).disabled = true
        this.partners.find(x => x.id === 3).disabled = true
        this.partner_id = 4
      }else if (this.shops.find(x=>x.id===id).admitad_id>0){
        this.partners.find(x => x.id === 3).disabled = false
        this.partner_id = 3
      }
    },
    createLink(){
      this.finalLink = false
      this.isCreating = true
      httpClient.post('partners/magic-link', QueryString.stringify(this.link)).then(({data})=>{
        this.isCreating = false
        this.finalLink = data.link
      })
    },
    checkOfferId(){ //для EPN и AdmitAD нужно выбирать ID оффера (магазина)
      if (this.shop_id > 0 && this.partner_id > 0){
        if (this.partner_id === 2){ //EPN
          this.offer_id = this.shops.find(x=>x.id===this.shop_id).epn_id
        }else if (this.partner_id === 3){ //admitad
          this.offer_id = this.shops.find(x=>x.id===this.shop_id).admitad_id
        } else{
          this.offer_id = 0
        }
      }else{
        this.offer_id = 0
      }
    },
    copy(link = false){
      this.snackbarLinkCopied = true
      if (link){
        navigator.clipboard.writeText(link)
      }else{
        navigator.clipboard.writeText(this.finalLink)
        this.clean()
        this.dialog = false
      }
    },
    clean(){
      this.offer_id = 0
      this.partner_id = 0
      this.shop_id = 0
      this.finalLink = ''
      this.raw_link = ''
      this.isCreating = false
      this.sub = ''
      this.sub1 = ''
      this.sub2 = ''
      this.successMessages = ''
      this.errorMessages = ''
      this.linkIcon = this.mdiInformationOutline
      this.linkChecking = false
    },
    timeLeft(time){
      return moment(time).fromNow()
    },
    checkAepLink(link){
      this.linkIcon = this.mdiInformationOutline
      this.linkChecking = true
      this.successMessages = ''
      this.errorMessages = ''
      httpClient.get(`partners/check-aep-link?link=${link}`).then(({data})=>{
        this.linkChecking = false
        if (data.result === 'affiliative'){
          this.successMessages = 'Аффилиат'
          this.linkIcon = this.mdiThumbUp
        } else if (data.result === 'affiliative_hotsale'){
          this.successMessages = 'Аффилиат и HotSale'
          this.linkIcon = this.mdiThumbUp
        } else if (data.result === 'not_affiliative'){
          this.errorMessages = 'Неаффилиат'
          this.linkIcon = this.mdiThumbDown
        }
      })
    }
  }
}
</script>

<style scoped>
  .text-yes-wrap{
    word-break: break-all;
  }
</style>
