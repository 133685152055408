import httpClient from './http.service'
import router from '../router'
import jwtDecode from 'jwt-decode'

/**
 * Created by TheCodeholic on 3/7/2020.
 */
const authService = {
  currentUser: null,
  isLoggedIn() {
    return !!localStorage.getItem('ACCESS_TOKEN')
  },
  getToken() {
    return localStorage.getItem('ACCESS_TOKEN')
  },
  async login(formData) {
    try {
      // formData.withCredentials = true
      // formData.crossDomain = true
      const {status, data} = await httpClient.post('site/login', formData, {withCredentials: true, crossDomain: true})
      if (status === 200) {
        localStorage.setItem('ACCESS_TOKEN', data.token)
        localStorage.setItem('user', JSON.stringify(data.user))
      }
      return {
        success: true,
        data
      }
    } catch (e) {
      console.log(e)
      return {
        success: false,
        errors: e
      }
    }
  },
  async register(formData) {
    try {
      const {status, data} = await httpClient.post('user/register', formData)
      if (status === 200) {
        localStorage.setItem('ACCESS_TOKEN', data.access_token)
      }
      return {
        success: true
      }
    } catch (e) {
      console.log(e.response)
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  logout() {
    localStorage.removeItem('ACCESS_TOKEN')
    router.push('/login')
  },
  // async getUser() {
  //   try {
  //     if (!this.currentUser) {
  //       const {status, data} = await httpClient.get('/user/data')
  //       if (status === 200) {
  //         this.currentUser = data
  //       }
  //     }
  //
  //   } catch (e) {
  //     return null
  //   }
  //
  //   return this.currentUser
  // },

  async refreshToken(){
    try {
      localStorage.setItem('refreshing', 'true')
      const { status, data } = await httpClient.post('site/refresh-token', null, {
        withCredentials: true,
        crossDomain: true
      })
      if (status === 200) {
        if (data.status === 'ok') {
          localStorage.setItem('ACCESS_TOKEN', data.token)
        }
      }
      return {
        success: true
      }
    } catch (e) {
      console.log(e.response)
      return {
        success: false,
        errors: e.response.data.errors
      }
    }
  },
  getUserRole(){
    const jwt = jwtDecode(localStorage.getItem('ACCESS_TOKEN'))
    return jwt.roles
  },
}

export default authService
