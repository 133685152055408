import axios from 'axios'
import authService from './auth.service'
import router from '../router'
// eslint-disable-next-line no-unused-vars
// import Toast from 'vue-toastification'
import { createToastInterface } from 'vue-toastification'
import jwtDecode from 'jwt-decode'
/** Default config for axios instance */
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'https://backend.telestatic.ru/'
const config = {
  baseURL: `${API_ENDPOINT}`
}

/** Creating the instance for axios */
const httpClient = axios.create(config)

/** Auth token interceptors */
const authInterceptor = config => {
  config.headers.Authorization = `Bearer ${authService.getToken()}`
  //надо проверить: если токен протух, то обновить его.
  if (localStorage.getItem('ACCESS_TOKEN')){
    const jwt = jwtDecode(localStorage.getItem('ACCESS_TOKEN'))
    if (jwt.exp > 0 && Number(jwt.exp) - Math.floor(Date.now() / 1000) < 86400){ //за сутки до окончания действия токена - обновим его
      console.log('token is expired!')
      if (localStorage.getItem('refreshing') !== 'true'){
        authService.refreshToken().then(()=>{
          localStorage.setItem('refreshing', 'false')
          return config
        })
      }
    }
  }
  return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    /** TODO: Add any response interceptors */
    return response
  },
  error => {
    console.log('interceptor: error: ', error)
    const toast = createToastInterface({
      timeout: 4000
    })
    if (error.response.status === 401){
      if (router.currentRoute.path !== '/login'){
        router.push({name: 'login'})
      }
    }
    if (error.response.status === 403){
      console.log('403!')
      toast.error(error.response.data.message)
    }
    else{
      toast.error('Ошибка при выполнении запроса')
    }
    /** TODO: Do something with response error */
    return Promise.reject(error)
  }
)

export default httpClient
