import {
// mdiAccountOutline,
// mdiCalendarOutline,
// mdiEmailOutline,
// mdiFileDocumentOutline,
// mdiFileOutline,
// mdiMessageTextOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'APPS AND PAGES',
    resource: 'Demo',
    action: 'read',
  },
  // {
  //   title: 'Пост с товаркой',
  //   icon: mdiCalendarOutline,
  //   to: 'CreatePost',
  // },
  // {
  //   title: 'Calendar',
  //   icon: mdiCalendarOutline,
  //   to: 'apps-calendar',
  //   resource: 'Demo',
  //   action: 'read',
  // },
  // {
  //   title: 'Chat',
  //   icon: mdiMessageTextOutline,
  //   to: 'apps-chat',
  //   resource: 'Demo',
  //   action: 'read',
  // },
  // {
  //   title: 'Email',
  //   icon: mdiEmailOutline,
  //   to: 'apps-email',
  //   resource: 'Demo',
  //   action: 'read',
  // },
  // {
  //   title: 'Invoice',
  //   icon: mdiFileDocumentOutline,
  //   children: [
  //     {
  //       title: 'List',
  //       to: 'apps-invoice-list',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Preview',
  //       to: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Edit',
  //       to: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Add',
  //       to: { name: 'apps-invoice-add' },
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //   ],
  // },
  // {
  //   title: 'User',
  //   icon: mdiAccountOutline,
  //   children: [
  //     {
  //       title: 'User List',
  //       to: 'apps-user-list',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'User View',
  //       to: { name: 'apps-user-view', params: { id: 21 } },
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   icon: mdiFileOutline,
  //   children: [
  //     {
  //       title: 'Authentication',
  //       children: [
  //         {
  //           title: 'Login V1',
  //           to: 'auth-login-v1',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Login V2',
  //           to: 'auth-login-v2',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Register V1',
  //           to: 'auth-register-v1',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Register V2',
  //           to: 'auth-register-v2',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Forgot Password V1',
  //           to: 'auth-forgot-password-v1',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Forgot Password V2',
  //           to: 'auth-forgot-password-v2',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Reset Password V1',
  //           to: 'auth-reset-password-v1',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Reset Password V2',
  //           to: 'auth-reset-password-v2',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       children: [
  //         {
  //           title: 'Under Maintenance',
  //           to: 'misc-under-maintenance',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Error',
  //           to: 'misc-error',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Coming Soon',
  //           to: 'misc-coming-soon',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           to: 'misc-not-authorized',
  //           target: '_blank',
  //           resource: 'Demo',
  //           action: 'read',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       to: 'page-knowledge-base',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Account Settings',
  //       to: 'page-account-settings',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Pricing',
  //       to: 'page-pricing',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //     {
  //       title: 'FAQ',
  //       to: 'page-faq',
  //       resource: 'Demo',
  //       action: 'read',
  //     },
  //   ],
  // },
]
