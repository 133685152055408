export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
]

export const posterAbility = [
  // {
  //   action: 'manage',
  //   subject: 'all'
  // },
  {
    action: 'read',
    subject: 'Public'
  },
  {
    action: 'read',
    subject: 'Reports'
  },
  {
    action: 'read',
    subject: 'Demo'
  },
  // {
  //   action: 'read',
  //   subject: 'Admin'
  // },
  // {
  //   action: 'export',
  //   subject: 'Reports'
  // },
  {
    action: 'read',
    subject: 'Posts'
  },
  {
    action: 'read',
    subject: 'Products'
  },
  {
    action: 'read',
    subject: 'User'
  },
  {
    action: 'read',
    subject: 'Demo'
  }
]

export const adminAbility = [
  {
    action: 'manage',
    subject: 'all'
  }]

export const _ = undefined
