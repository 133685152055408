import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'
import apps from './apps'
import dashboard from './dashboard'
import pages from './pages'
import userInterface from './user-interface'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('user'))
      const userRole = userData && userData.role ? userData.role : null
      if (userRole.hasOwnProperty('admin')) return { name: 'dashboard-crm' }
      if (userRole.hasOwnProperty('poster')) return { name: 'dashboard-crm' }
      return { name: 'auth-login', query: to.query }
    },
  },

  // {
  //   name: 'Dashboard',
  //   path: '',
  //   component: () => import('@/views/dashboard/Dashboard'),
  // },
  {
    name: 'CreateSimplePost',
    path: '/posts/simple/create',
    component: () => import('@/views/posts/SimplePost'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Новый пост',
        parent: 'dashboard-crm'
      },
      title: 'Новый пост'
    },
  },
  {
    name: 'CreatePost',
    path: '/posts/create',
    component: () => import('@/views/posts/CreatePost'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Новый пост с товаркой',
        parent: 'dashboard-crm'
      },
      title: 'Новый пост'
    },
  },
  {
    name: 'CreateSalePost',
    path: '/posts/create-sale',
    component: () => import('@/views/posts/CreateSale'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Новый пост с акцией',
        parent: 'dashboard-crm'
      },
      title: 'Новая акция'
    },
  },
  {
    name: 'ShowPosts',
    path: '/posts/index',
    component: () => import('@/views/posts/Posts'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Посты',
        parent: 'dashboard-crm'
      },
      title: 'Посты'
    },
  },
  {
    name: 'SimplePost',
    path: '/posts/simple-post',
    component: () => import('@/views/posts/SimplePost'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Новый пост',
        parent: 'dashboard-crm'
      },
      title: 'Посты'
    },
  },
  {
    name: 'SimplePosts',
    path: '/posts/simple-posts',
    component: () => import('@/views/posts/SimplePosts'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Посты',
        parent: 'dashboard-crm'
      },
      title: 'Посты'
    },
  },
  {
    name: 'ShowSimplePost',
    path: '/posts/simple-post/:id',
    component: () => import('@/views/posts/SimplePost'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Пост',
        parent: 'dashboard-crm'
      },
      title: 'Пост'
    },
  },
  {
    name: 'ShowPost',
    path: '/posts/:id',
    component: () => import('@/views/posts/Post'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Пост',
        parent: 'dashboard-crm'
      },
      title: 'Пост'
    },
  },
  {
    name: 'ShowSales',
    path: '/sales',
    component: () => import('@/views/sales/ShowSales'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      breadcrumb: {
        label: 'Результаты',
        parent: 'dashboard-crm'
      },
      title: 'Акции'
    },
  },
  {
    name: 'CreateSale',
    path: '/sales/create',
    component: () => import('@/views/sales/CreateSale'),
    meta: {
      layout: 'content',
      resource: 'Posts',
      action: 'read',
      title: 'Новая акция'
    },
  },
  {
    name: 'Ad',
    path: '/ad/index',
    component: () => import('@/views/ad/AdCabinet'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb: {
        label: 'Реклама',
        parent: 'dashboard-crm'
      },
      title: 'Рекламный кабинет'
    },
  },
  {
    name: 'ShowAdChannels',
    path: '/ad/channels',
    component: () => import('@/views/ad/AdChannels'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb: {
        label: 'Каналы',
        parent: 'Ad'
      },
      title: 'Каналы'
    },
  },
  {
    name: 'CreateAdChannel',
    path: '/ad/channels/create',
    component: () => import('@/views/ad/CreateChannel'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb: {
        label: 'Новый канал',
        parent: 'ShowAdChannels'
      },
      title: 'Новый канал'
    },
  },
  {
    name: 'ShowAdChannel',
    path: '/ad/channels/:id',
    component: () => import('@/views/ad/ShowChannel'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb() {
        const { meta } = this.$route
        return {
          label: meta.title || 'Канал',
          parent: 'ShowAdChannels'
        }
      },
      title: 'Канал'
    },
  },
  {
    name: 'ViewAdPosts',
    path: '/ad/posts',
    component: () => import('@/views/ad/AdPosts'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb: {
        label: 'Посты',
        parent: 'Ad'
      },
      title: 'Рекламные посты'
    },
  },
  // {
  //   name: 'EditAdPost',
  //   path: '/ad/posts/:id/edit',
  //   component: () => import('@/views/ad/EditPost'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'Ads',
  //     action: 'read',
  //     breadcrumb() {
  //       const { meta } = this.$route
  //       return {
  //         label: meta.title || 'Пост',
  //         parent: 'ViewAdPosts'
  //       }
  //     },
  //     title: 'Рекламный пост - изменить'
  //   },
  // },
  {
    name: 'ViewAdPost',
    path: '/ad/posts/:id',
    component: () => import('@/views/ad/ShowAdPost'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb() {
        const { meta } = this.$route
        return {
          label: meta.title,
          parent: 'ViewAdPosts'
        }
      },
      title: 'Рекламный пост'
    },
  },
  {
    name: 'ViewAdWelcomePosts',
    path: '/ad/welcome',
    component: () => import('@/views/ad/AdWelcomePosts'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb() {
        const { meta } = this.$route
        return {
          label: meta.title,
          parent: 'Ad'
        }
      },
      title: 'Велкомы'
    },
  },
  {
    name: 'ViewAdWelcomePost',
    path: '/ad/welcome/:id',
    component: () => import('@/views/ad/ShowAdWelcomePost'),
    meta: {
      layout: 'content',
      resource: 'Ads',
      action: 'read',
      breadcrumb() {
        const { meta } = this.$route
        return {
          label: meta.title,
          parent: 'ViewAdWelcomePosts'
        }
      },
      title: 'Велком пост'
    },
  },
  {
    name: 'AepPostbacks',
    path: '/reports/aep-postbacks',
    component: () => import('@/views/reports/AepPostbacks'),
    meta: {
      layout: 'content',
      resource: 'Reports',
      action: 'export',
      breadcrumb: 'Экспорт',
      title: 'Экспорт'
    },
  },
  {
    name: 'ShowPostbacks',
    path: '/reports/show-postbacks',
    component: () => import('@/views/reports/ShowPostbacks'),
    meta: {
      layout: 'content',
      resource: 'Reports',
      action: 'read',
      title: 'Постбэки'
    },
  },
  {
    name: 'saleRepost',
    path: '/reports/1111',
    component: () => import('@/views/reports/1111'),
    meta: {
      layout: 'content',
      resource: 'Reports',
      action: 'export',
      title: 'Экспорт'
    },
  },
  {
    name: 'productsIndex',
    path: '/products/index',
    component: () => import('@/views/products/ProductsIndex'),
    meta: {
      layout: 'content',
      resource: 'Products',
      action: 'read',
      title: 'Продукты'
    },
  },
  {
    name: 'ShowProduct',
    path: '/products/show/:id',
    component: () => import('@/components/products/ShowProduct'),
    meta: {
      layout: 'content',
      resource: 'Products',
      action: 'read',
      title: 'Продукт'
    },
  },
  {
    name: 'ShowProxies',
    path: '/admin/proxies',
    component: () => import('@/views/proxies/ShowProxies'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Список прокси'
    },
  },
  {
    name: 'rbacIndex',
    path: '/admin/rbac',
    component: () => import('@/views/rbac/Index'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Контроль доступа'
    },
  },
  {
    name: 'coeffs',
    path: '/admin/coeffs',
    component: () => import('@/views/admin/coeffs/Index'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Коэффициенты распродаж'
    },
  },
  {
    name: 'shops',
    path: '/admin/shops',
    component: () => import('@/views/admin/shops/Index'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Магазины'
    },
  },
  {
    name: 'expLinksIndex',
    path: '/ad/index',
    component: () => import('@/views/ad/AdChannels'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Рекламный кабинет'
    },
  },
  {
    name: 'AdPostsIndex',
    path: '/ad/posts/index',
    component: () => import('@/views/ad/AdPosts'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      action: 'read',
      title: 'Рекламные посты'
    },
  },
  /// ////
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      action: 'read',
      title: 'Упс... 404'
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
      action: 'read',
      title: 'Авторизация'
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      action: 'read',
      redirectIfLoggedIn: true,
      title: 'Регистрация'
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },
  ...dashboard,
  ...userInterface,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  document.title = to.meta.title || 'TeleCRM'
  const userData = localStorage.getItem('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    console.log('cannot navigate!')
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
