import {
  mdiPost,
  mdiSale,
  mdiViewList,
  mdiArchiveSearch
} from '@mdi/js'

export default [
  {
    subheader: 'ПОСТЫ',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Обычный пост',
    icon: mdiPost,
    to: 'CreateSimplePost',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Пост с товаркой',
    icon: mdiPost,
    to: 'CreatePost',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Список постов',
    icon: mdiViewList,
    to: 'ShowPosts',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Пост с акцией',
    icon: mdiSale,
    to: 'CreateSale',
    resource: 'Public',
    action: 'read',
  },
  {
    title: 'Все товары',
    icon: mdiArchiveSearch,
    to: 'productsIndex',
    resource: 'Products',
    action: 'read',
  }
]
