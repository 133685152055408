import '@/@fake-db/db'
import Vue from 'vue'
import Toast from 'vue-toastification'
import VueBreadcrumbs from 'vue-2-breadcrumbs'
import App from './App'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import 'vue-toastification/dist/index.css'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')

Vue.use(Toast)
// Vue.use(VueBreadcrumbs)
Vue.use(VueBreadcrumbs, {
  template:
  `<div v-if="$breadcrumbs.length > 1" class="row align-left dense">
     <ul style="align-items: center;display: flex;flex-wrap: wrap;flex: 0 1 auto;list-style-type: none;margin: 0;padding: 18px 12px;">
        <template v-for="(crumb, key) in $breadcrumbs" v-if="crumb.meta.breadcrumb">
          <li style="align-items: center;display: inline-flex;font-size: 14px;">
            <router-link :to="{ path: getPath(crumb) }" style="align-items: center;display: inline-flex;text-decoration: none;transition: .3s cubic-bezier(.25,.8,.5,1);">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>
          </li>
          <li v-if="$breadcrumbs.length > Number(key + 1)" style="color: rgba(0,0,0,.38); padding: 0 12px;">/</li>
        </template>
      </ul>
  </div>`
    // '        <nav v-if="$breadcrumbs.length" aria-label="breadcrumb">\n' +
    // '            <ol class="breadcrumb">\n' +
    // '                <li v-for="(crumb, key) in $breadcrumbs" v-if="crumb.meta.breadcrumb" :key="key" class="breadcrumb-item active" aria-current="page">\n' +
    // '                    <router-link :to="{ path: getPath(crumb) }">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>' +
    // '                </li>\n' +
    // '            </ol>\n' +
    // '        </nav>'
    // <div class="row align-center justify-center"><ul class="v-breadcrumbs theme--light" file="v-breadcrumbs/usage"><li><a href="breadcrumbs_dashboard" class="v-breadcrumbs__item">Dashboard</a></li><li class="v-breadcrumbs__divider">/</li><li><a href="breadcrumbs_link_1" class="v-breadcrumbs__item">Link 1</a></li><li class="v-breadcrumbs__divider">/</li><li><a href="breadcrumbs_link_2" class="v-breadcrumbs__item v-breadcrumbs__item--disabled">Link 2</a></li></ul></div>
})
