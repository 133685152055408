import { mdiArchiveSearch, mdiPost, mdiSale, mdiViewList, mdiFileExport } from '@mdi/js'

export default [
  {
    subheader: 'ОТЧЁТЫ',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'Постбэки: экспорт',
    icon: mdiFileExport,
    to: 'AepPostbacks',
    resource: 'Reports',
    action: 'export',
  },
  {
    title: 'Постбэки: LIVE',
    icon: mdiPost,
    to: 'ShowPostbacks',
    resource: 'Reports',
    action: 'read',
  },

]
