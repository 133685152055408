const dashboard = [
  {
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/views/dashboards/crm/Crm'),
    meta: {
      layout: 'content',
      resource: 'Public',
      action: 'read',
      breadcrumb: {
        label: 'TeleCRM',
      },
      title: 'TeleCRM'
    },
  },
  // {
  //   path: '/dashboards/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboards/analytics/Analytics'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'Public',
  //     action: 'read',
  //   },
  // },
  // {
  //   path: '/dashboards/eCommerce',
  //   name: 'dashboard-eCommerce',
  //   component: () => import('@/views/dashboards/ecommerce/Ecommerce'),
  //   meta: {
  //     layout: 'content',
  //     resource: 'Public',
  //     action: 'read',
  //   },
  // },
]

export default dashboard
