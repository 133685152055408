import appAndPages from './app-and-pages'
// import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
// import formsAndTables from './forms-and-tables'
import others from './others'
import posts from './posts'
// import uiElements from './ui-elements'
import reports from '@/navigation/vertical/reports'
import admin from '@/navigation/vertical/admin'
import ads from '@/navigation/vertical/ads'

// Array of sections
// export default [...dashboard, ...posts, ...reports, ...appAndPages, ...uiElements, ...formsAndTables, ...chartsAndMaps, ...admin, ...others]
export default [...dashboard, ...posts, ...reports, ...ads, ...admin, ...others, ...appAndPages]
