import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import app from './app'
import httpClient from '@/services/http.service'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    version: '1.5.0',
    userAbility: null,
    user: JSON.parse(localStorage.getItem('user')),
    users: [],
    appVersion: process.env.VUE_APP_VERSION || '0',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '/assets/sidebar-1.jpg',
    drawer: null,
    needUpdateResultPost: false,
    needUpdateProduct: false, // чтобы заставить EditProduct сохранить продукт
    needReloadScheduleEvents: false,
    needSaveResultPost: false,
    needSaveProduct: false,
    shops: [],
    schedule: [],
    channels: [], //каналы
    partners: [],
    dashboard: {sum: 0, count: 0, updated_at: '0'},
    saleStats: {1: {sum: 0, count: 0}, 2: {sum: 0, count: 0}, 3: {sum: 0, count: 0}, 4: {sum: 0, count: 0}, 5: {sum: 0, count: 0}},
    userSubs: [], //тут просто список кастомных сабов которые менджеры используют при ручном создании ссылок
    showProduct: {}, //здесь находится объект с product, используется для просмотра инфо о продукте в компоненте /products/showProduct
    productStatCats: [], //тут категории для статистики по шаблонам
    magicLinks: [], //тут последние созданные "магические" ссылки
    productCats: [], //список категорий продуктов
    productTags: [], //список тегов,
    postText: '', //итоговый текст для создающегося поста
    canUpdateResultText: true,
    screenshots: [], // сюда попадает информация о новых скриншотах (по приколу, из расширения)
    adChannels: [], //тут каналы для рекламы
    adPosts: [], //тут шаблоны постов,
    adWelcomes: [], //тут приветственные посты (если чел заходит в закрытый канал по ссылке, бот может ему написать первым (типа пройти проверку))
  },
  getters:{
    needUpdateResultPost: state => {return state.needUpdateResultPost},
    needSaveResultPost: state => {return state.needSaveResultPost},
    needSaveProduct: state => {return state.needSaveProduct},
    dashboardSum: state => {return state.dashboard.sum},
    dashboardCount: state => {return state.dashboard.count},
    dashboardUpdatedAt: state => {return state.dashboard.updated_at},
    dashboard: state => {return state.dashboard},
    saleStats: state => {return state.saleStats},
  },
  mutations: {
    setUserAbility(state, payload){state.userAbility = payload},
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    needUpdateResultPost (state, payload) { state.needUpdateResultPost = payload },
    needUpdateProduct (state, payload) { state.needUpdateProduct = payload },
    needReloadScheduleEvents(state, payload) {state.needReloadScheduleEvents = payload},
    needSaveResultPost(state, payload) {state.needSaveResultPost = payload},
    needSaveProduct(state, payload) {state.needSaveProduct = payload},
    setUsers(state, payload) {state.users = payload},
    setShops(state, payload) {state.shops = payload},
    setSchedule(state, payload) {state.schedule = payload},
    setChannels(state, payload) {state.channels = payload},
    setPartners(state, payload) {state.partners = payload},
    setDashboard(state, payload) {state.dashboard = payload; state.saleStats = payload.saleStats},
    addDashboardOrder(state, payload) {
      state.dashboard.sum = Number(state.dashboard.sum) + Number(payload.commission)
      state.dashboard.count = Number(state.dashboard.count) + Number(1)
      state.dashboard.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
      if (payload.user){
        state.dashboard.saleStats[payload.user].count++
        const userSum = Number(state.dashboard.saleStats[payload.user].sum) + Number(payload.commission)
        state.dashboard.saleStats[payload.user].sum = userSum.toFixed(2)
      }
    },
    setUserSubs(state, payload) {state.userSubs = payload},
    setShowProduct(state, payload) { state.showProduct = payload},
    setProductStatsCats(state, payload) {state.productStatCats = payload},
    setMagicLinks(state, payload) {state.magicLinks = payload},
    setProductCats(state, payload) {state.productCats = payload},
    setProductTags(state, payload) {state.productTags = payload},
    setPostText(state, payload) {state.postText = payload},
    canUpdateResultText(state, payload) {state.canUpdateResultText = payload},
    addScreenshot(state, payload) {state.screenshots.push(payload)},
    setAdPosts(state, payload) {state.adPosts = payload},
    setAdChannels(state, payload) {state.adChannels = payload},
    setAdWelcomes(state, payload) {state.adWelcomes = payload},
  },
  actions: {
    setUserAbility(context, val){
      context.commit('setUserAbility', val)
    },
    needUpdateResultPost(context, val){
      context.commit('needUpdateResultPost', val)
    },
    needUpdateProduct(context, val){
      context.commit('needUpdateProduct', val)
    },
    needSaveResultPost(context, val){
      context.commit('needSaveResultPost', val)
    },
    needReloadScheduleEvents(context, val) {context.commit('needReloadScheduleEvents', val)},
    needSaveProduct(context, val) {context.commit('needSaveProduct', val)},
    async getUsers(context){
      await httpClient.get('site/users').then(({data})=>{
        context.commit('setUsers', data)
      })
    },
    async getShops(context, force){
      if (context.state.shops.length < 1 || force){
        await httpClient.get('shops').then(({data})=>{
          context.commit('setShops', data)
          return data
        })
      }
      return context.state.shops
    },
    async getSchedule(context, force = false){
      if (context.state.shops.length < 1 || force){
        await httpClient.get('posts/show-schedule').then(({data})=>{
          context.commit('setSchedule', data)
          return data
        })
      }
      return context.state.schedule
    },
    async getChannels(context, force = false){
      if (context.state.channels.length < 1 || force){
        await httpClient.get('channels').then(({data})=>{
          context.commit('setChannels', data)
          return data
        })
      }
      return context.state.channels
    },
    async getPartners(context, force = false){
      if (context.state.partners.length < 1 || force){
        await httpClient.get('partners').then(({data})=>{
          context.commit('setPartners', data)
          return data
        })
      }
      return context.state.partners
    },
    async getDashboard(context, force = false){
      if (context.state.dashboard.length < 1 || force){
        await httpClient.get('dashboard').then(({data})=>{
          context.commit('setDashboard', data)
          return data
        })
      }
      return context.state.dashboard
    },
    addDashboardOrder(context, val){
      context.commit('addDashboardOrder', val)
    },
    async getUserSubs(context){
      if (context.state.userSubs.length < 1){
        await httpClient.get('partners/user-subs').then(({data}) => {
          context.commit('setUserSubs', data)
        })
      }
    },
    setShowProduct(context, params){
      context.commit('setShowProduct', params)
    },
    async getProductStatCats(context){
      await httpClient.get('products/stat-cats').then(({data})=>{
        context.commit('setProductStatsCats', data)
      })
    },
    async getMagicLinks(context, force = false){
      if (context.state.magicLinks.length < 1 || force){
        await httpClient.get('partners/magic-links').then(({data})=>{
          context.commit('setMagicLinks', data)
          return data
        })
      }
      return context.state.magicLinks
    },
    async getProductCats(context, force = false){
      if (context.state.productCats.length <1 || force){
        await httpClient.get('product-cats').then(({data})=>{
          context.commit('setProductCats', data)
          return data
        })
      }
      return context.state.productCats
    },
    async getProductTags(context, force = false){
      if (context.state.productTags.length < 1 || force){
        await httpClient.get('product-tags').then(({data})=>{
          context.commit('setProductTags', data)
          return data
        })
      }
      return context.state.productTags
    },
    setPostText(context, text){
      context.commit('setPostText', text)
    },
    canUpdateResultText(context, value){
      context.commit('canUpdateResultText', value)
    },
    addScreenshot(context, value){
      context.commit('addScreenshot', value)
    },
    getAdChannels(context){
      httpClient.get('ad-channels').then(({data})=>{
        context.commit('setAdChannels', data)
      })
    },
    getAdPosts(context){
      httpClient.get('ad-posts').then(({data})=>{
        context.commit('setAdPosts', data)
      })
    },
    getAdWelcomes(context){
      httpClient.get('ad-welcomes').then(({data})=>{
        context.commit('setAdWelcomes', data)
      })
    }
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
