import {
  mdiBadgeAccountHorizontal, mdiBookmarkMultiple, mdiCurrencyUsd
} from '@mdi/js'

export default [
  {
    subheader: 'Реклама',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Закупки',
    icon: mdiCurrencyUsd,
    to: { path: '/ad/index' },
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Каналы',
    icon: mdiBadgeAccountHorizontal,
    to: { path: '/ad/channels'},
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Шаблоны постов',
    icon: mdiBookmarkMultiple,
    to: { path: '/ad/posts'},
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Велкомы',
    icon: mdiBookmarkMultiple,
    to: { path: '/ad/welcome'},
    resource: 'Admin',
    action: 'read',
  }
]
