import {
  mdiHuman, mdiArrowDecisionAutoOutline, mdiCosineWave, mdiBasket
} from '@mdi/js'

export default [
  {
    subheader: 'АДМИНКА',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Роли пользователей',
    icon: mdiHuman,
    to: { path: '/admin/rbac'},
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Прокси',
    icon: mdiArrowDecisionAutoOutline,
    to: { path: '/admin/proxies'},
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Коэффициенты',
    icon: mdiCosineWave,
    to: { path: '/admin/coeffs'},
    resource: 'Admin',
    action: 'read'
  },
  {
    title: 'Магазины',
    icon: mdiBasket,
    to: { path: '/admin/shops'},
    resource: 'Admin',
    action: 'read'
  },

]
